import React, { useState } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { urlApi } from '../Constants/Global';

function SectionNews({ contents }) {
  const minPercentage = 80;
  const maxPercentage = 160;
  const [selectCategory, setSelectCategory] = useState('');

  const handleClick = (sel_item) => {
    console.log("handleClick", sel_item);
    setSelectCategory(sel_item);
  }

  return (
    <section className="pt-5 pb-5">
      <Container className='pt-5 pb-5'>
        <>
          <Row className="mb-5">
            <Col className="text-center">
              <ul className="p-0 m-0">
                <li className="d-inline-block px-2 mb-3">
                  <Button variant='outline-light' onClick={() => handleClick('')} className="rounded-pill fs-4 px-5">All</Button>
                </li>
                {Array.isArray(contents.categories) ? contents.categories.map((item, index) => (
                  <li key={index} className="d-inline-block px-2 mb-3">
                    <Button variant='outline-light' onClick={() => handleClick(`[${item.row_key}]`)} className="rounded-pill fs-4 px-5">{item.name}</Button>
                  </li>
                )) : ''}
              </ul>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} className="as-column-3">
              {selectCategory !== '' ?
                Array.isArray(contents.items) ? contents.items.map((item, index) => (
                  item.all_key.includes(selectCategory) ?
                    <div className='pin-card'>
                      <Link to={`/News/${item.row_slug}`} className='text-decoration-none'>
                        <Card className='w-100 border-0 rounded-0 mb-5 overflow-hidden'>
                          <Card.Img className="rounded-0" variant="top" src={`${urlApi}/storage/article/${item.row_value}`} />
                          <Card.Title className='position-relative text-white bg-dark w-100 p-3 px-4 fs-5 m-0'>
                            <span className='position-absolute start-0 ps-4 fs-6' style={{ marginTop: '-50px' }}>{item.event_date}</span>
                            <span className='position-absolute end-0 pe-4 fs-6' style={{ marginTop: '-50px' }}>{item.trainer}</span>
                            <span className='text-white text-decoration-none'>{item.name}</span>
                          </Card.Title>
                        </Card>
                      </Link>
                    </div>
                    : ''
                )) : '' :
                Array.isArray(contents.items) ? contents.items.map((item, index) => (
                  <div className='pin-card'>
                    <Link to={`/News/${item.row_slug}`} className='text-decoration-none'>
                      <Card className='w-100 border-0 rounded-0 mb-5 overflow-hidden'>
                        <Card.Img className="rounded-0" variant="top" src={`${urlApi}/storage/article/${item.row_value}`} />
                        <Card.Title className='position-relative text-white bg-dark w-100 p-3 px-4 fs-5 m-0'>
                          <span className='position-absolute start-0 ps-4 fs-6' style={{ marginTop: '-50px' }}>{item.event_date}</span>
                          <span className='position-absolute end-0 pe-4 fs-6' style={{ marginTop: '-50px' }}>{item.trainer}</span>
                          <span className='text-white text-decoration-none'>{item.name}</span>
                        </Card.Title>
                      </Card>
                    </Link>
                  </div>
                )) : ''
              }
            </Col>
          </Row>
        </>
      </Container>
    </section >
  )
}

SectionNews.defaultProps = {
  contents: {
    categories: [],
    items: []
  }
}

export default SectionNews