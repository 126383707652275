import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import FormContact from '../Components/FormContact';
import ObjSocialList from './ObjSocialList';

const SectionContact = ({ contents }) => {
  return (
    <>
      <section id="contact" className="pt-5 pb-5">
        <Container className="text-white mb-5">
          <div className="fs-1 mb-5 text-center">ติดต่อเรา</div>
          <Row>
            <Col md={12} lg={4} className="px-2 px-md-5">
              <div className='fs-4 mb-3 mb-md-5 mt-0 mt-md-0 fw-bold'>ที่อยู่</div>
              <p className="mb-2">{contents?.contact?.company}</p>
              <p>{contents?.contact?.address}</p>
            </Col>
            <Col md={12} lg={4} className="px-2 px-md-5">
              <div className='fs-4 mb-3 mb-md-5 mt-5 mt-md-0 fw-bold'>ช่องทางติดต่อ</div>
              <p className="mb-0">{contents?.contact?.phone}</p>
              <p className="mb-2">{contents?.contact?.email}</p>
              <ObjSocialList
                facebook={contents?.contact?.facebook}
                youtube={contents?.contact?.youtube}
                instagram={contents?.contact?.instagram}
                tiktok={contents?.contact?.tiktok}
                line={contents?.contact?.line}
              />
            </Col>
            <Col md={12} lg={4} className="px-2 px-md-5">
              <div className='fs-4 mb-3 mb-md-5 mt-5 mt-md-0 fw-bold'>เวลาทำการ</div>
              <p className="mb-0">{contents?.contact?.["work-day"]}</p>
              <p className="mb-0">{contents?.contact?.["work-hours"]}</p>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col md={12} lg={6} className="p-0 px-2 px-md-0">
              <iframe src={contents?.contact?.["google-maps"]} width="100%" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="googlemaps"></iframe>
            </Col>
            <Col md={12} lg={6} className="px-2 px-md-5 pt-4">
              <div className="fs-3 text-white mb-4">ส่งข้อความหาเรา</div>
              <FormContact />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default SectionContact