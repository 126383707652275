import React from 'react';
import SectionHeader from '../Components/SectionHeader';
import Class from '../Components/CustomSetup/Class';

function Course({ contents }) {
  return (
    <>
      <SectionHeader topic="Course" textColor={'text-white'} textAlign={'top-50 start-50 text-center'} />
      <Class contents={contents} />
    </>
  )
}

Course.defaultProps = {
  contents: {}
}

export default Course