import React from 'react';

const ContentStyle = () => {
  // Define the styles as an object
  const styles = {
    '.HTML-Displayer': {
      'line-height': '1'
    },
    '.HTML-Displayer .ql-align-right': {
      'text-align': 'right', 
      'margin-bottom': '0'
    },
    '.HTML-Displayer .ql-align-center': {
      'text-align': 'center', 
      'margin-bottom': '0'
    },
    '.HTML-Displayer .ql-align-left': {
      'text-align': 'left', 
      'margin-bottom': '0'
    },
    '.HTML-Displayer p': {
      'margin-bottom': '0', 
      'line-height': '1.2'
    }, 
    '.HTML-Displayer .ql-size-small': {
      'font-size': '0.75em'
    }, 
    '.HTML-Displayer .ql-size-large': {
      'font-size': '1.5em'
    }, 
    '.HTML-Displayer .ql-size-huge': {
      'font-size': '2.5em'
    }, 
    '.content-image-viewer': {
      'background': '#1E1E1E', 
    }, 
    '.content-image-viewer .modal-content': {
      'background': 'transparent', 
      'border': '0'
    }, 
    '.content-image-viewer .modal-header': {
      'border': '0'
    }, 
    '.content-image-viewer .modal-header .btn-close': {
      'color': 'white'
    }, 
    '.content-image-viewer .carousel-indicators': {
      'bottom': '-40px'
    }, 
    '.content-image-viewer .carousel-control-prev': {
      'left': '-100px'
    }, 
    '.content-image-viewer .carousel-control-next': {
      'right': '-100px'
    }, 
    '.content-image-viewer .carousel .carousel-indicators button': {
      'background-color': 'white !important'
    }, 
    '.content-image-viewer .carousel .carousel-control-next-icon, .content-image-viewer .carousel .carousel-control-prev-icon': {
      'filter': 'invert(0) grayscale(100) !important'
    }, 
  };

  // Create CSS classes dynamically
  let styleSheet = '';
  for (const className in styles) {
    styleSheet += `${className} {`;
    for (const property in styles[className]) {
      styleSheet += `${property}: ${styles[className][property]};`;
    }
    styleSheet += `}\n`;
  }

  return (
    <style>{styleSheet}</style>
  );
};

export default ContentStyle;
