import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { urlApi } from '../../Constants/Global';

const Student = ({ contents, path }) => {
  return (
    <>
      <Row className='mb-5 bg-white'>
        <Col>
          <Container>
            <Row>
              {Array.isArray(contents) ? (
                contents.map((item, index) => (
                  <Col key={index} sm={6} md={3} className="p-0 p-lg-5 px-5 text-center d-flex justify-content-center">
                    <img src={`${path}${urlApi}/storage/media/${item.set1}`} alt="Student" className='w-50 align-self-center' />
                  </Col>
                ))) : ''}
            </Row>
          </Container>
        </Col>
      </Row>
    </>
  )
}

Student.defaultProps = {
  contents: [],
  path: ''
}

export default Student