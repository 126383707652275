import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { MessengerChat } from 'react-messenger-chat-plugin';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles/core.css';
import { baseName } from './Constants/Global';
import favicon from './Images/logo/favicon.png';
import SiteHelmet from './Components/SiteHelmet';
import MainMenu from './Components/MainMenu';
import MainFooter from './Components/MainFooter';
import MainCopyright from './Components/MainCopyright';
import Home from './Pages/Home';
import About from './Pages/About';
import Article from './Pages/Article';
import ArticleView from './Pages/ArticleView';
import Contact from './Pages/Contact';
import Course from './Pages/Course';
import Event from './Pages/Event';
import News from './Pages/News';
import ObjRouteManager from './Components/ObjRouteManager';
import ScrollToTop from './Components/ScrollToTop';
import CourseCategory from './Pages/CourseCategory';
import CornerContact from './Components/CornerContact';

const App = () => {
  const [datas, setDatas] = useState({});

  const routeChangeHandler = (contents) => {
    console.log('routeChangeHandler', contents);
    setDatas(contents);
  }

  return (
    <>
      <BrowserRouter basename={baseName}>
        <MessengerChat pageId='105271218405416' />
        <ObjRouteManager changeHandler={routeChangeHandler}></ObjRouteManager>
        <ScrollToTop>
          <SiteHelmet favicon={favicon} author="thewoz" title="thewoz" />
          <MainMenu />
          <Container fluid>
            <Routes>
              <Route path="/" element={<Home contents={datas} />} />
              <Route path="/About" element={<About contents={datas} />} />
              <Route path="/Article/:slug" element={<ArticleView contents={datas} />} />
              <Route path="/Course" element={<Course contents={datas} />} />
              <Route path="/Course/:slug" element={<ArticleView contents={datas} />} />
              <Route path="/CourseCategory" element={<Course contents={datas} />} />
              <Route path="/CourseCategory/:id" element={<CourseCategory contents={datas} />} />
              <Route path="/Event" element={<Event contents={datas} />} />
              <Route path="/Event/:slug" element={<ArticleView contents={datas} />} />
              <Route path="/News" element={<News contents={datas} />} />
              <Route path="/News/:slug" element={<ArticleView contents={datas} />} />
              <Route path="/Contact" element={<Contact contents={datas} />} />
            </Routes>
          </Container>
          <MainFooter contents={datas} />
          <MainCopyright />
          <CornerContact contents={datas} />
        </ScrollToTop>
      </BrowserRouter>
    </>
  )
}

export default App