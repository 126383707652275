import React from 'react';
import { Row } from 'react-bootstrap';
import ContentText from './ContentText';
import ContentImage from './ContentImage';
import ContentYoutube from './ContentYoutube';
import ContentYoutubeShort from './ContentYoutubeShort';

const ContentLayout = ({ content, storage }) => {
  const cols = content.row_class.split('-');

  return (
    <>
      {cols.map((col, index) => {
        console.log(index, col);
        let result;

        switch (col) {
          case 'text':
            result = <ContentText content={content["detail" + (index + 1)]} size={12 / cols.length} />;
            break;
          case 'image':
            result = <>
              {content["detail" + (index + 1)] !== '' ?
                <ContentImage src={storage + content["detail" + (index + 1)]} size={12 / cols.length} />
                : <ContentText content={''} size={12 / cols.length} />}
            </>;
            break;
          case 'html':
            result = <ContentText content={content["detail" + (index + 1)]} size={12 / cols.length} />;
            break;
          case 'youtubeshort':
            result = <ContentYoutubeShort url={content["detail" + (index + 1)]} size={12 / cols.length} />;
            break;
          case 'youtube':
            result = <ContentYoutube url={content["detail" + (index + 1)]} size={12 / cols.length} />;
            break;
          default:
            result = <ContentText content={''} size={12 / cols.length} />;
        }

        return (
          <>
            {result}
          </>
        );
      })}
    </>
  )
}

ContentLayout.defaultProps = {
  content: {},
  storage: ''
}

export default ContentLayout