import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { urlApi } from '../../Constants/Global';

function HomeCourse({ contents }) {
  const bgRadial = [
    'radial-gradient(circle at 0% 0%, rgb(249, 89, 60), #00423E 80%)',
    'radial-gradient(circle at 0% 0%, #1B2252, rgb(249, 89, 60) 80%)',
    'radial-gradient(circle at 0% 0%, #1B2252, #00423E 80%)'
  ]

  return (
    <Col className='pt-5 pb-5 px-0'>
      <Container fluid>
        <Row>
          {contents.map((item, index) => (
            <Col xs={12} className="m-0 p-0" key={index}>
              <Link to={`/CourseCategory/${item.row_slug}`}>
                <div className='position-relative bg-img ratio-21x9' style={{ backgroundImage: item.row_value === null ? bgRadial[1] : `url(${urlApi}/storage/master/${item.row_value})` }}>
                  <div className={`position-absolute text-white p-5 ${item.row_option === '' ? 'bottom-0 text-center w-100' : item.row_option}`}>
                    <h2>{item.name}</h2>
                    <h3>{item.detail}</h3>
                  </div>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </Col >
  )
}

HomeCourse.defaultProps = {
  isHome: false,
  contents: []
}

export default HomeCourse