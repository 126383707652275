import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { urlApi, useFullApi } from '../Constants/Global';
import SectionHeader from '../Components/SectionHeader';
import ContentDisplay from '../Components/ContentDisplay';

const ArticleView = ({ contents }) => {
  console.log(contents)
  const storage = useFullApi ? `${urlApi}/storage/article/` : `../${urlApi}/storage/article/`;

  return (
    <>
      <SectionHeader topic={contents?.content?.item?.name} textColor={'text-white'} textAlign={'top-50 start-50 text-center'} />
      {contents?.content?.item?.row_json !== null && contents?.content?.item?.row_json !== '' ? <>
        <Row className='pb-5 px-0 px-sm-5 fs-5 fw-light text-white'>
          <Col md={8} className='text-white'>
            <p className='mb-0'>{contents?.content?.item?.row_json.level}</p>
            <p className='mb-0'>{contents?.content?.item?.row_json.lesson}</p>
            <p className='mb-0'>{contents?.content?.item?.row_json.period}</p>
          </Col>
          <Col md={4} className='text-end'>
            {contents?.content?.item?.row_json.price === '' || contents?.content?.item?.row_json.price === 0 ? '' :
              <h3 className='mb-0 text-decoration-line-through'>
                {contents?.content?.item?.row_json.price}
              </h3>}
            <h1>
              {contents?.content?.item?.row_json.discount_price === '' || contents?.content?.item?.row_json.discount_price === 0 ? '' : contents?.content?.item?.row_json.discount_price}
            </h1>
          </Col>
        </Row>
        <Row className='pb-5 px-0 px-sm-5 fs-5 fw-light text-white'>
          <Col>
            <p className='fw-bold fs-5 mb-1'>{contents?.content?.item?.detail}</p>
            <p>{contents?.content?.item?.row_json.detail2}</p>
          </Col>
        </Row>
      </> : <></>}
      <ContentDisplay contents={contents?.content?.contents} storage={storage} lang={contents.lang} />
    </>
  )
}

ArticleView.defaultProps = {
  contents: {
    content: {
      item: {},
      contents: []
    }
  }
}

export default ArticleView