import React, { useState } from 'react';
import { Col, Modal, Carousel } from 'react-bootstrap';

const ContentImage = ({ src, size, width, height, styleString, layoutClass }) => {
  const [viewerShowStatus, setViewerShowStatus] = useState(false);
  const useViewer = true;
  const [viewerActiveIndex, setViewerActiveIndex] = useState('');
  const [viewerImages, setViewerImages] = useState([]);

  let styleObj = {};

  if (styleString && styleString.trim() !== '') {
    try {
      styleObj = JSON.parse(styleString);
    } catch (error) {
      console.error('Error parsing styleString:', error);
    }
  }

  const imageClickHandler = (src, event) => {
    console.log('imageClickHandler', src, event, event.target);
    if (useViewer) {
      const images = document.querySelectorAll('.content-image');
      const arrImg = [];

      images.forEach((image, index) => {
        console.log(`Image ${index + 1}: ${image.src}`);
        arrImg.push(image.src);

        if (image === event.target) {
          setViewerActiveIndex(index);
        }
        // You can perform further actions with each image, such as modifying attributes, adding classes, etc.
      });
      setViewerImages(arrImg);
      setViewerShowStatus(true);
    }
  }

  const handleSelect = (selectedIndex) => {
    setViewerActiveIndex(selectedIndex);
  };

  return (
    <>
      <Col xs={12} sm={12} md={12} lg={size} xl={size} xxl={size} className={`d-flex justify-content-center flex-wrap ${layoutClass}`}>
        {src !== '' ? (
          <img src={src} style={{ width: width, height: height, alignSelf: 'center', ...styleObj }} className='content-image' alt='Content' onClick={(event) => imageClickHandler(src, event)} />
        ) : ('')}
      </Col>
      <Modal
        className='content-image-viewer'
        show={viewerShowStatus}
        onHide={() => setViewerShowStatus(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        data-theme="dark"
        data-bs-theme='dark'
        centered
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <Carousel activeIndex={viewerActiveIndex} onSelect={handleSelect}>
            {viewerImages.map((image, index) => (
            <Carousel.Item className="ratio-4x3 bg-img"
              style={{backgroundColor: 'transparent', backgroundSize: 'contain', backgroundImage: `url(${image})`}}
            ></Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>
    </>
  )
}

ContentImage.defaultProps = {
  src: '',
  size: 12,
  width: '100%',
  height: 'auto',
  styleString: ''
}

export default ContentImage