import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { urlApi } from '../Constants/Global';
import NoPhoto from '../Images/lib/no-image.svg';

const CardItem = ({ content }) => {
  return (
    <Link className='text-decoration-none' to={`/Course/${content.row_slug}`}>
      <Card className='rounded-0 border-0 mb-4'>
        <div className='bg-img ratio-16x9 rounded-0' style={{ backgroundImage: `url(${content.row_value !== '' && content.row_value !== null ? `../${urlApi}/storage/article/${content.row_value}` : NoPhoto})` }}></div>
        <Card.Title className='pt-2 position-relative' style={{ minHeight: '240px', paddingBottom: '80px' }}>
          <h5 className='px-3'>{content.name}</h5>
          <h6 className='px-3 fw-bold'>{content.detail}</h6>
          <h6 className='px-3 text-black-80'>{content.row_json?.detail2}</h6>
          <div className='position-absolute bottom-0 w-100 px-3'>
            {content?.row_json.discount_price === '' || content?.row_json.discount_price === 0 ? '' :
              <h6 className='mb-0 text-decoration-line-through'>
                <small>{content?.row_json?.price}</small>
              </h6>}
            <h5 className='mb-0'>
              {content?.row_json?.discount_price === '' || content?.row_json?.discount_price === 0 ? '' : content?.row_json?.discount_price}
            </h5>
          </div>
          <div className='position-absolute bottom-0 w-100 px-3 text-end'>
            <h6 className='mb-0'>
              <small className='float-end text-black-50'>
                <span className='d-block mb-0'>{content?.row_json?.level}</span>
                <span className='d-block mb-0'>{content?.row_json?.lesson}</span>
                <span className='d-block mb-0'>{content?.row_json?.period}</span>
              </small>
            </h6>
            <h5>
              <span className='float-end text-decoration-underline fs-6' style={{ clear: 'both' }}>
                <Link className='tx-color-secondary' to={`/Course/${content?.row_slug}`}>รายละเอียด</Link>
              </span>
            </h5>
          </div>
        </Card.Title>
      </Card>
    </Link>
  )
}

export default CardItem