import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

const ContentImageViewer = ({ images, show, onClose }) => {
  const [showStatus, setShowStatus] = useState(show);

  const handleClose = () => {
    setShowStatus(false);
  }

  return (
    <Modal
      show={showStatus} 
      onHide={handleClose} 
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <h4>Centered Modal</h4>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p>
      </Modal.Body>
    </Modal>
  )
}

ContentImageViewer.defaultProps = {
  show: false, 
  images: []
}

export default ContentImageViewer