import React from 'react';
import SectionHeader from '../Components/SectionHeader';
import SectionNews from '../Components/SectionNews';

const News = ({contents}) => {
  return (
    <>
      <SectionHeader topic="News & Content" textColor={'text-white'} textAlign={'top-50 start-50 text-center'} />
      <SectionNews contents={contents.content} />
    </>
  )
}

News.defaultProps = {
  contents: {
    content: {
      categories: [], 
      items: []
    }
  }
}

export default News