// PRD = /api/2.0
// PRD in subfolder = ../api/2.0
// DEV = http://envphp:8080/api/2.0
// PRD = api/2.0
// CALL PRD = http://www.domain.com/api/2.0
// CALL PRD HTTPS = https://www.domain.com/api/2.0
// const urlApi = "../api/2.0";
const urlApi = "api/2.0"; // ../api/2.0
const baseName = "";
const useFullApi = false;
const apiEndpointMapping = {
  '/': { module: "home", call: 'get-content', callParam: '' },
  '/Article': { module: "article", call: 'get-content', callParam: 'get-content-by-slug' },
  '/ArticleView': { module: "article", call: 'get-content', callParam: '' },
  '/Course': { module: "course", call: 'get-contents', callParam: 'get-content-by-slug' },
  '/CourseCategory': { module: "course", call: 'get-contents', callParam: 'get-content-by-category' },
  '/Event': { module: "article", call: 'get-events', callParam: 'get-content-by-slug' },
  '/News': { module: "article", call: 'get-news', callParam: 'get-content-by-slug' },
  '/About': { module: "about", call: 'get-content', callParam: '' },
  '/ClassOnSite': { module: "home", call: 'get-content', callParam: '' },
  '/ClassMaster': { module: "home", call: 'get-content', callParam: '' },
  '/ClassOnline': { module: "home", call: 'get-content', callParam: '' },
  '/Contact': { module: "home", call: 'get-content', callParam: '' }
};

export { urlApi, useFullApi, baseName, apiEndpointMapping }