import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { urlApi } from '../../Constants/Global';

function HomeClass({ contents }) {
  const bgRadial = [
    'radial-gradient(circle at 0% 0%, rgb(249, 89, 60), #00423E 80%)',
    'radial-gradient(circle at 0% 0%, #1B2252, rgb(249, 89, 60) 80%)',
    'radial-gradient(circle at 0% 0%, #1B2252, #00423E 80%)'
  ]

  return (
    <Col className='pt-5 pb-5 px-0'>
      <Container fluid>
        <Row>
          {contents.map((item, index) => (
            <Col xs={12} className="mb-4" key={index}>
              <Link to={`/Event/${item.row_slug}`}>
                <div className='position-relative bg-img ratio-21x9' style={{ backgroundImage: item.row_value === null ? bgRadial[1] : `url(${urlApi}/storage/article/${item.row_value})` }}>
                  <div className='position-absolute bottom-0 text-white bg-dark w-100 p-3 px-4 fs-5'>
                    <span className='position-absolute start-0 ps-4 fs-6' style={{ marginTop: '-50px' }}>{item.event_date}</span>
                    <span className='position-absolute end-0 pe-4 fs-6' style={{ marginTop: '-50px' }}>{item.trainer}</span>
                    {item.name}
                  </div>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </Col>
  )
}

HomeClass.defaultProps = {
  isHome: false,
  contents: []
}

export default HomeClass