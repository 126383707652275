import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Icon1 from '../Images/mockup/icon-1.webp';
import Icon2 from '../Images/mockup/icon-2.webp';
import Icon3 from '../Images/mockup/icon-3.webp';

const SectionService = ({ contents }) => {
  return (
    <>
      <section id="service">
        <Container fluid>
          <Row>
            {contents.map((item, index) => (
              <Col key={index} className="p-0 position-relative text-center bg-img bg-color-primary p-5" style={{ backgroundImage: 'url(' + '' + ')' }}>
                <img src={item.row_value} alt="icon" className="m-5" />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  )
}

SectionService.defaultProps = {
  contents: [
    { id: 1, name: 'On-Site Class & Workshop', row_value: Icon1 },
    { id: 1, name: 'Masterclass for Education & Corporate', row_value: Icon2 },
    { id: 1, name: 'Online Class', row_value: Icon3 }
  ]
}

export default SectionService