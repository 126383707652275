import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Logo from '../Images/logo/logo_s-wt.png';

const MainCopyright = () => {
  return (
    <>
      <section id="copyright" className="bg-color-black text-white pt-2 pb-2">
        <Container fluid>
          <Row>
            <Col className="text-center text-md-start">
              <ul className="p-0 m-0">
                <li className="d-inline-block me-2">
                  <Link to="/Article/privacy-policy" className="text-white fw-light">นโยบายความเป็นส่วนตัว</Link>
                </li>
                <li className="d-inline-block me-2">
                  <Link to="/Article/cookies-policy" className="text-white fw-light">นโยบายคุ้กกี้</Link>
                </li>
              </ul>
            </Col>
            <Col className="text-center text-md-end fw-light">&copy;2023 by thewoz</Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

MainCopyright.defaultProps = {
  
}

export default MainCopyright