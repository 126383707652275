import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { urlApi } from '../../Constants/Global';
import { FaFacebook, FaYoutube } from 'react-icons/fa';

function Trainer({ contents }) {
  return (
    <section>
      <Container className='pt-5 pb-5'>
        <Row className='pt-5 mb-5'>
          <Col className='text-center text-white fs-1'>
            ผู้สอนของเรา
          </Col>
        </Row>
        <Row className='pt-5'>
          {Array.isArray(contents) ? (contents.map((item, index) => (
            <Col xs={12} md={4} className='mb-5 px-5'>
              <div className='mb-5 text-center text-white'>
                <div className='ratio-1x1 rounded-circle bg-img' style={{ backgroundImage: `url(${urlApi}/storage/media/${item.set1})` }}></div>
                <div className='fs-4 mt-3'>{item.set2}</div>
                {item.set3 ? (
                  <div className='fs-5 fw-light'>( {item.set3} )</div>
                ) : ''}
                <ul className='p-0 m-0'>
                  {item.set5 ? (
                    <li className='d-inline-block fs-4 px-1'>
                      <a href={item.set5} target='_blank'><FaFacebook color={'#FF5A3C'} /></a>
                    </li>
                  ) : ''}
                  {item.set4 ? (
                    <li className='d-inline-block fs-4 px-1'>
                      <a href={item.set4} target='_blank'><FaYoutube color={'#FF5A3C'} /></a>
                    </li>
                  ) : ''}
                </ul>
              </div>
            </Col>
          ))) : ''}
        </Row>
      </Container>
    </section>
  )
}

Trainer.defaultProps = {
  contents: []
}

export default Trainer