import React from 'react';
import { FaLine, FaYoutube, FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa';

function ObjSocialList(props) {
  return (
    <ul className='p-0 m-0'>
      <li className={'d-inline-block ' + props.iconSpace}>
        <a href="https://www.facebook.com/woztraining" target="_blank" rel='noreferrer'>
          <FaFacebookF size={props.iconSize} color={props.iconColor} />
        </a>
      </li>
      <li className={'d-inline-block ' + props.iconSpace}>
        <a href="https://www.youtube.com/@woz.channel" target="_blank" rel='noreferrer'>
          <FaYoutube size={props.iconSize} color={props.iconColor} />
        </a>
      </li>
      <li className={'d-inline-block ' + props.iconSpace}>
        <a href="https://instagram.com/woz_channel?igshid=MzRlODBiNWFlZA==" target="_blank" rel='noreferrer'>
          <FaInstagram size={props.iconSize} color={props.iconColor} />
        </a>
      </li>
      <li className={'d-inline-block ' + props.iconSpace}>
        <a href="https://www.tiktok.com/@woz_channel?is_from_webapp=1&sender_device=pc" target="_blank" rel='noreferrer'>
          <FaTiktok size={props.iconSize} color={props.iconColor} />
        </a>
      </li>
      <li className={'d-inline-block ' + props.iconSpace}>
        <a href="http://lin.ee/QmY49NM" target="_blank" rel='noreferrer'>
          <FaLine size={props.iconSize} color={props.iconColor} />
        </a>
      </li>
    </ul>
  )
}

ObjSocialList.defaultProps = {
  iconSize: 26,
  iconColor: '#FFF',
  iconSpace: 'pe-4'
}

export default ObjSocialList