import React from 'react'

function SectionHeader({ topic, detail, textAlign, textColor, bg }) {
  return (
    <>
      <section className="section-header text-center text-white p-5 px-0 px-sm-5">
        <div className="fs-1 text-uppercase tx-spacing-30 mt-0 mt-md-5 px-0 px-md-5">{topic}</div>
        <div className="fs-3 text-capitalize mb-0 mb-md-5 px-0 px-md-5">{detail}</div>
      </section>
    </>
  )
}

SectionHeader.defaultProps = {
  topic: '',
  detail: '',
  textAlign: 'top-50 start-50 text-center',
  textColor: 'text-white',
  bg: 'radial-gradient(circle at 0% 0%, #1B2252, #00423E 80%)'
}

export default SectionHeader