import React, { useState } from 'react';
import { urlApi } from '../Constants/Global';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { BiEnvelope } from 'react-icons/bi';

const FormContact = ({ lang }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '',
    message: ''
  });
  const [modalOption, setModalOption] = useState({
    title: '',
    message: '',
    buttonLabel: 'ปิด',
    show: false
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  }

  const handleSubmit = (event) => {
    console.log("handleSubmit", formData);
    event.preventDefault();

    var form_data = new FormData();
    form_data.append("subject", formData.subject);
    form_data.append("name", formData.name);
    form_data.append("phone", formData.phone);
    form_data.append("email", formData.email);
    form_data.append("message", formData.message);

    var url = urlApi + '/?module=web&call=message-submit';

    fetch(url, {
      method: 'POST',
      body: form_data
    })
      .then(res => res.json())
      .then(result => {
        console.log("messageSent", result.returnStatus.code, result);

        if (result.returnStatus.code === "0000") {
          setModalOption({
            ...modalOption,
            title: lang === 'th' ? 'ดำเนินการเรียบร้อย' : 'Successful',
            message: lang === 'th' ? 'ได้รับข้อความเรียบร้อย ขอบคุณที่ติดต่อหาเรา' : 'Message received successfully. Thank you for contacting us.',
            show: true
          });
          setFormData({
            name: '',
            phone: '',
            email: '',
            subject: '',
            message: ''
          });
        } else {
          setModalOption({
            ...modalOption,
            title: lang === 'th' ? 'ดำเนินการไม่สำเร็จ' : 'Failed',
            message: lang === 'th' ? 'ขออภัยไม่สามารถส่งข้อความได้' : 'Sorry, unable to send the message.',
            show: true
          })
        }
      });
  }

  const handleClose = () => {
    console.log("handleClose");
    setModalOption({
      ...modalOption,
      show: false
    })
  }

  return (
    <>
      <Form className="form-contact text-white" onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={12}>
            <label htmlFor="name" className="form-label mb-1">
              {lang === 'th' ? "ชื่อ-นามสกุล" : 'Name'}<span className="restrict"></span>
            </label>
            <input type="text"
              className="form-control mb-3"
              id="name"
              name="name"
              placeholder={lang === 'th' ? "ระบุชื่อ-นามสกุล" : 'Input Name'}
              onChange={handleInputChange}
              value={formData.name}
            />
          </Col>
          <Col xs={12} md={6}>
            <label htmlFor="phone" className="form-label mb-1">
              {lang === 'th' ? "เบอร์โทรศัพท์" : 'Phone'}<span className="restrict"></span>
            </label>
            <input type="text"
              className="form-control mb-3"
              id="phone"
              name="phone"
              placeholder={lang === 'th' ? "ระบุเบอร์โทรศัพท์" : 'Input Phone'}
              value={formData.phone}
              onChange={handleInputChange}
            />
          </Col>
          <Col xs={12} md={6}>
            <label htmlFor="phone" className="form-label mb-1">
              {lang === 'th' ? "อีเมล" : 'Email'}<span className="restrict"></span>
            </label>
            <input type="text"
              className="form-control mb-3"
              id="email"
              name="email"
              placeholder={lang === 'th' ? "ระบุอีเมล" : 'Input Email'}
              value={formData.email}
              onChange={handleInputChange}
            />
          </Col>
          <Col xs={12}>
            <label htmlFor="subject" className="form-label mb-1">
              {lang === 'th' ? 'เรื่อง' : 'Subject'}<span className="restrict"></span>
            </label>
            <input type="text"
              className="form-control mb-3"
              id="subject"
              name="subject"
              placeholder={lang === 'th' ? 'ระบุเรื่อง' : 'Input Subject'}
              value={formData.subject}
              onChange={handleInputChange}
            />
          </Col>
          <Col xs={12}>
            <label htmlFor="message" className="form-label mb-1">
              {lang === 'th' ? 'ข้อความ' : 'Message'}
            </label>
            <textarea type="text"
              className="form-control mb-3"
              id="message"
              name="message"
              style={{ height: '100px' }}
              placeholder={lang === 'th' ? 'ระบุข้อความ' : 'Input Message'}
              value={formData.message}
              onChange={handleInputChange}
            ></textarea>
          </Col>
          <Col xs={12} className="text-start">
            <Button type="submit" className="btn fs-5 px-5 fw-bold bg-color-secondary border-color-secondary">
              <BiEnvelope className="me-2" size={30} /> {lang === 'th' ? 'ส่งข้อความ' : 'SEND MESSAGE'}
            </Button>
          </Col>
        </Row>
      </Form>

      <Modal size="sm" show={modalOption.show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="itx-primary">{modalOption.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="itx-dark">
          {modalOption.message}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn ibt-primary px-4" onClick={handleClose}>
            {modalOption.buttonLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

FormContact.defaultProps = {
  lang: 'th'
};

export default FormContact