import React, { useState } from 'react';
import { Overlay, Popover, Button } from 'react-bootstrap';
import { FaLine } from "react-icons/fa6";

const CornerContact = ({ contents }) => {
  const style = {
    bottom: '30px',
    right: '30px',
    width: '75px',
    height: '75px',
    backgroundColor: 'var(--color-secondary)',
    borderRadius: '50%',
    cursor: 'pointer',
    textAlign: 'center',
    position: 'fixed'
  };

  const popoverStyle = { fontStyle: 'normal', fontWeight: 'normal' };

  const [show, setShow] = useState(true);
  const [target, setTarget] = useState(null);

  const handleClick = (event) => {
    window.open(contents?.contact?.line);
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body className='p-3 font-default' style={popoverStyle}>
        สมัครเรียนกับเราแอดไลน์เลย
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      {contents?.contact?.line ?
        <>
          <Button
            className='position-fixed text-white border-0'
            style={style}
            onClick={handleClick}
            ref={(button) => { if (button && !target) setTarget(button); }}
          >
            <div>
              <FaLine size={'80%'} style={{ margin: '10%' }} />
            </div>
          </Button>
          <Overlay show={show} target={target} placement="top" containerPadding={20}>
            {popover}
          </Overlay>
        </>
        : ''}
    </>
  );
}

export default CornerContact;
