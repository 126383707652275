import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from '../Images/logo/logo-wt.png';
import ObjSocialList from './ObjSocialList';

function MainFooter({contents}) {
  return (
    <footer id="footer" className="pt-5 pb-5 bg-color-black">
      <Container className="text-center">
        <Row>
          <Col>
            <img src={Logo} alt="Logo" className="logo mb-4" />
          </Col>
        </Row>
        <Row>
          <Col>
            <ul className="p-0 m-0 mb-4">
              <li className="d-inline-block mx-4">
                <Link to="/" className="text-white text-decoration-none">Home</Link>
              </li>
              <li className="d-inline-block mx-4">
                <Link to="/About" className="text-white text-decoration-none">About</Link>
              </li>
              <li className="d-inline-block mx-4">
                <Link to="/Course" className="text-white text-decoration-none">Courses</Link>
              </li>
              <li className="d-inline-block mx-4">
                <Link to="/Event" className="text-white text-decoration-none">Events</Link>
              </li>
              <li className="d-inline-block mx-4">
                <Link to="/News" className="text-white text-decoration-none">News & Content</Link>
              </li>
              <li className="d-inline-block mx-4">
                <Link to="/Contact" className="text-white text-decoration-none">Contact</Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <ObjSocialList iconSpace="px-3" 
              facebook={contents?.contact?.facebook} 
              youtube={contents?.contact?.youtube} 
              instagram={contents?.contact?.instagram} 
              tiktok={contents?.contact?.tiktok} 
              line={contents?.contact?.line} 
            />
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default MainFooter