import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { urlApi } from '../../Constants/Global';

function Class({ contents }) {
  console.log("Class Value :", contents);

  const bgRadial = [
    'radial-gradient(circle at 0% 0%, rgb(249, 89, 60), #00423E 80%)',
    'radial-gradient(circle at 0% 0%, #1B2252, rgb(249, 89, 60) 80%)',
    'radial-gradient(circle at 0% 0%, #1B2252, #00423E 80%)'
  ];

  // Ensure contents and contents.content are arrays
  const contentArray = Array.isArray(contents?.content) ? contents.content : [];

  return (
    <Row>
      {contentArray.map((item, index) => (
        <Col xs={12} className="m-0 p-0" key={index}>
          <Link to={`/CourseCategory/${item.row_slug}`}>
            <div className='course-cover position-relative bg-img ratio-21x9' style={{ backgroundImage: item.row_value === null ? bgRadial[1] : `url(${urlApi}/storage/master/${item.row_value})` }}>
              <div className={`position-absolute text-white p-5 ${item.row_option === '' ? 'bottom-0 text-center w-100' : item.row_option}`}>
                <div className='fw-bold mb-2 font-header' style={{ fontSize: '3vw', lineHeight: 1 }}>{item.name}</div>
                <div style={{ fontSize: '1.5vw' }}>{item.detail}</div>
              </div>
            </div>
          </Link>
        </Col>
      ))}
    </Row>
  );
}

Class.defaultProps = {
  isHome: false,
  contents: {
    content: []
  }
};

export default Class;
