import React from 'react';
import Helmet from 'react-helmet';

function SiteHelmet(props) {
  return (
    <Helmet>
      <title>{props.title}</title>
      <link rel='icon' type={props.faviconType} href={props.favicon} />
      <meta name="description" content={props.description} />
      <meta name="keywords" content={props.keywords} />
      <meta name="author" content={props.author} />
    </Helmet>
  )
}

SiteHelmet.defaultProps = {
  title: '', 
  favicon: null, 
  faviconType: 'image/png', 
  keywords: '', 
  description: '', 
  author: ''
}

export default SiteHelmet