import React from 'react';
import { urlApi } from '../Constants/Global';
import SectionHeader from '../Components/SectionHeader';
import { Container } from 'react-bootstrap';
import ContentDisplay from '../Components/ContentDisplay';

const About = ({contents}) => {
  console.log(contents)
  const storage = urlApi + '/storage/article/';

  return (
    <>
      <SectionHeader topic="About Us" textColor={'text-white'} textAlign={'top-50 start-50 text-center'} />
      <Container fluid>
        <ContentDisplay contents={contents?.content?.contents} storage={storage} lang={contents.lang} />
      </Container>
    </>
  )
}

export default About