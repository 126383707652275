import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import FormContact from '../FormContact';
import ObjSocialList from '../ObjSocialList';

const HomeContact = ({contents}) => {
  return (
    <>
      <section id="contact" className="pt-5 pb-5">
        <Container className="text-white mb-5">
          <div className="fs-1 mb-5 text-center">ติดต่อเรา</div>
          <Row>
            <Col md={12} lg={4} className="px-2 px-sm-5">
              <div className='fs-4 mb-5 fw-bold'>ที่อยู่</div>
              <p className="mb-2">บริษัท เยลโล บริค โรด จำกัด</p>
              <p>388/43 ชิค โฮมออฟฟิศ ชั้น 4 รามคำแหง 53 (จันทร์ศรีชวาลา) แขวงพลับพลา เขตวังทองหลาง กรุงเทพมหานคร 10310</p>
            </Col>
            <Col md={12} lg={4} className="px-2 px-sm-5">
              <div className='fs-4 mb-5 fw-bold'>ช่องทางติดต่อ</div>
              <p className="mb-0">+66 96 167 6250</p>
              <p className="mb-2">contact@thewoz.co</p>
              <ObjSocialList />
            </Col>
            <Col md={12} lg={4} className="px-2 px-sm-5">
              <div className='fs-4 mb-5 fw-bold'>เวลาทำการ</div>
              <p className="mb-0">จันทร์ - ศุกร์</p>
              <p className="mb-0">9:00 - 18:00</p>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col md={12} lg={6} className="p-0">
              <iframe src={contents['google-maps']} width="100%" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="googlemaps"></iframe>
            </Col>
            <Col md={12} lg={6} className="px-2 px-sm-5 pt-4">
              <div className="fs-3 text-white mb-4">ส่งข้อความหาเรา</div>
              <FormContact />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default HomeContact