import React from 'react';
import SectionHeader from '../Components/SectionHeader';
import SectionContact from '../Components/SectionContact';

function Contact({contents}) {
  return (
    <>
      <SectionHeader topic="Contact" textColor={'text-white'} textAlign={'top-50 start-50 text-center'} />
      <SectionContact contents={contents} />
    </>
  )
}

export default Contact