import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Offcanvas, Container } from 'react-bootstrap';
import Logo from '../Images/logo/logo-wt.png';
import LogoSm from '../Images/logo/logo.png';

const MainMenu = () => {
  const expand = 'lg';
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const openOffcanvas = () => {
    console.log("openOffcanvas");
    setShowOffcanvas(true);
  }

  const closeOffcanvas = () => {
    console.log("closeOffcanvas");
    setShowOffcanvas(false);
  }

  return (
    <>
      <Navbar expand={expand} id="main-menu" className="bg-color-secondary" sticky='top'>
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            <img src={Logo} className="logo mx-3" alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={openOffcanvas} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
            show={showOffcanvas}
            onHide={closeOffcanvas}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <img src={LogoSm} className="logo mx-3" alt="Logo" />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link onClick={closeOffcanvas} className="fs-5 text-black text-lg-white px-4" as={Link} to="/">Home</Nav.Link>
                <Nav.Link onClick={closeOffcanvas} className="fs-5 text-black text-lg-white px-4" as={Link} to="/About">About</Nav.Link>
                <Nav.Link onClick={closeOffcanvas} className="fs-5 text-black text-lg-white px-4" as={Link} to="/Course">Courses</Nav.Link>
                <Nav.Link onClick={closeOffcanvas} className="fs-5 text-black text-lg-white px-4" as={Link} to="/Event">Events</Nav.Link>
                <Nav.Link onClick={closeOffcanvas} className="fs-5 text-black text-lg-white px-4" as={Link} to="/News">News & Content</Nav.Link>
                <Nav.Link onClick={closeOffcanvas} className="fs-5 text-black text-lg-white px-4" as={Link} to="/Contact">Contact</Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  )
}

export default MainMenu