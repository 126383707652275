import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Photo1 from '../Images/mockup/partner-1.png';
import Photo2 from '../Images/mockup/partner-2.png';
import Photo3 from '../Images/mockup/partner-3.png';
import Photo4 from '../Images/mockup/partner-4.png';
import Photo5 from '../Images/mockup/partner-5.png';
import Photo6 from '../Images/mockup/partner-6.png';
import Photo7 from '../Images/mockup/partner-7.png';
import Photo8 from '../Images/mockup/partner-8.png';

const SectionPartner = ({ contents }) => {
  return (
    <>
      <section id="partner">
        <Container>
          <Row>
            {contents.map((item, index) => (
            <Col key={index} md={3} className="p-5">
              <img src={item.row_value} alt="Partner" className='w-100' />
            </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  )
}

SectionPartner.defaultProps = {
  contents: [
    {row_value: Photo1}, 
    {row_value: Photo2}, 
    {row_value: Photo3}, 
    {row_value: Photo4}, 
    {row_value: Photo5}, 
    {row_value: Photo6}, 
    {row_value: Photo7}, 
    {row_value: Photo8}
  ]
}

export default SectionPartner