import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Icon1 from '../Images/mockup/c1.webp';
import Icon2 from '../Images/mockup/c2.webp';
import Icon3 from '../Images/mockup/c3.webp';

const SectionServiceFull = ({ contents }) => {
  return (
    <>
      <section id="service">
        <Container fluid>
          <Row>
            {contents.map((item, index) => (
              <Col key={index} xs={12} className="p-0">
                <div className="position-relative ratio-21x9 text-center bg-img bg-color-primary" style={{ backgroundImage: 'url(' + item.row_value + ')' }}>
                  {item.is_available ?
                    <Link to={item.target}>
                      <Button variant='outline-light' className="position-absolute rounded-pill bottom-0 translate-middle mb-5 fs-4 px-5">
                        Learn More
                      </Button>
                    </Link>
                  : 
                  <Button variant='outline-light' className="position-absolute rounded-pill bottom-0 translate-middle mb-5 fs-4 px-5">Coming Soon</Button>
                  }
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  )
}

SectionServiceFull.defaultProps = {
  contents: [
    { id: 1, name: 'On-Site Class & Workshop', row_value: Icon1, target: '/ClassOnSite', is_available: true },
    { id: 1, name: 'Masterclass for Education & Corporate', row_value: Icon2, target: '/ClassMaster', is_available: true },
    { id: 1, name: 'Online Class', row_value: Icon3, target: '/ClassOnline', is_available: false }
  ]
}

export default SectionServiceFull