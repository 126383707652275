import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';

const SectionCarousel = ({ contents }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <section id="hero">
        <Carousel activeIndex={index} onSelect={handleSelect}>
          {contents.map((item, index) => (
            <Carousel.Item key={index} className="ratio-21x9 bg-img" style={{backgroundImage: 'url(' + item.row_value + ')'}}>
              <div className="position-absolute w-100 h-100">
                <Carousel.Caption className="top-50 start-50 translate-middle w-100 text-center">
                  {item.detail}
                </Carousel.Caption>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </section>
    </>
  )
}

SectionCarousel.defaultProps = {
  contents: []
}

export default SectionCarousel