import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import SectionHeader from '../Components/SectionHeader';
import FormContact from '../Components/FormContact';
import MultiItemCarousel from '../Components/MultiItemCarousel';
import CardItem from '../Components/CardItem';
import Student from '../Components/CustomSetup/Student';

function CourseCategory({ contents }) {
  console.log("CourseList :", contents);
  let result;

  switch (contents?.content?.row_layout) {
    case 'card-carousel':
      result = <Container className='pb-5 mb-5'>
        {contents?.categories?.map((category, index) => (
          <>
            {contents?.categories?.length > 1 ?
              <Row>
                <Col>
                  <h3 className='text-white mb-4'>
                    {category.name}
                    <Link className='float-end fs-6 mt-2 tx-color-secondary' to={`/CourseCategory/${category.row_slug}`}>เพิ่มเติม</Link>
                  </h3>
                </Col>
              </Row>
              : ''}
            <Row key={index}>
              <Col>
                <MultiItemCarousel items={category.items} sm={1} md={4} lg={3} />
              </Col>
            </Row>
          </>
        ))}
      </Container>;
      break;
    case 'card-list':
      result = <Container className='pb-5 mb-5'>
        {contents?.categories?.map((category, index) => (
          <>
            {contents?.categories?.length > 1 ?
              <Row>
                <Col>
                  <h3 className='text-white mb-4'>
                    {category.name}
                    {/* <Link className='float-end fs-6 mt-2 tx-color-secondary' to={`/CourseCategory/${category.row_slug}`}>เพิ่มเติม</Link> */}
                  </h3>
                </Col>
              </Row>
              : ''}
            <Row className='mb-5'>
              {category.items?.map((item, sub_index) => (
                <Col key={index + '-' + sub_index} sm={12} md={4}>
                  <CardItem content={item} />
                </Col>
              ))}
            </Row>
          </>
        ))}
      </Container>;
      break;
    case 'form-contact':
      result = <>
        <Container className='pb-5 mb-5 text-white'>
          <Row>
            <Col sm={{ span: 12, offset: 0 }} md={{ span: 6, offset: 3 }} className='text-center mb-5'>
              <h4>สำหรับองค์กรที่สนใจคอร์สเรียน ด้วยคอร์สที่ออกแบบตามความต้องการของผู้เรียน หรือลักษณะของงาน</h4>
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 12, offset: 0 }} md={{ span: 6, offset: 3 }}>
              <h2 className='mb-4'>โปรดติดต่อเรา</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 12, offset: 0 }} md={{ span: 6, offset: 3 }}>
              <FormContact />
            </Col>
          </Row>
          {contents?.students?.length > 0 ?
            <Row className='pt-5 mt-5 text-center'>
              <Col sm={{ span: 12, offset: 0 }} md={{ span: 6, offset: 3 }}>
                <h2>ลูกค้าที่ไว้วางใจ</h2>
              </Col>
            </Row>
            : ''}
        </Container>
        <Student contents={contents?.students} path={'../../'} />
      </>;
      break;
    case 'coming-soon':
      result = <Container className='pb-5 mb-5'>
        <Row>
          <Col sm={{ span: 12, offset: 0 }} md={{ span: 6, offset: 3 }}>
            <div className='p-5 text-center text-white'>
              <h3>Coming Soon</h3>
            </div>
          </Col>
        </Row>
      </Container>;
      break;
    default:
      result = <Container className='pb-5 mb-5'>
        <Row>
          <Col sm={{ span: 12, offset: 0 }} md={{ span: 6, offset: 3 }}>
            <div className='p-5 text-center text-white'>
              <h3></h3>
            </div>
          </Col>
        </Row>
      </Container>;
  }

  return (
    <>
      <SectionHeader topic={contents?.content?.name} textColor={'text-white'} textAlign={'top-50 start-50 text-center'} />
      <>
        {result}
      </>
    </>
  );
}

CourseCategory.defaultProps = {
  contents: {}
};

export default CourseCategory;
