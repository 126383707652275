import React from 'react';
import SectionHeader from '../Components/SectionHeader';
import SectionEvent from '../Components/SectionEvent';

const Event = ({contents}) => {
  return (
    <>
      <SectionHeader topic="Event" textColor={'text-white'} textAlign={'top-50 start-50 text-center'} />
      <SectionEvent contents={contents.content} />
    </>
  )
}

Event.defaultProps = {
  contents: []
}

export default Event