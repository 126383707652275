import React from 'react';
import { urlApi } from '../Constants/Global';
import ContentDisplay from '../Components/ContentDisplay';

import SectionCarousel from '../Components/SectionCarousel';
import SectionService from '../Components/SectionService';
import SectionServiceFull from '../Components/SectionServiceFull';
import SectionPartner from '../Components/SectionPartner';
import SectionContact from '../Components/SectionContact';
import SectionEvent from '../Components/SectionEvent';
import SectionNews from '../Components/SectionNews';
import Logo from '../Images/logo/logo_s-wt.png';
import Photo from '../Images/mockup/pexels-junior-teixeira-2047905.jpg';
import Photo1 from '../Images/mockup/event-1.webp';
import Photo2 from '../Images/mockup/event-2.webp';
import Photo3 from '../Images/mockup/event-3.webp';

const Home = ({contents}) => {
  console.log(contents)
  const storage = urlApi + '/storage/article/';

  return (
    <>
      <ContentDisplay contents={contents?.content?.contents} storage={storage} lang={contents.lang} />

      {/* <SectionCarousel contents={props.slides} />
      <SectionEvent isHome={true} />
      <SectionCarousel contents={props.slide_events} />
      <SectionServiceFull />
      <SectionNews isHome={true} />
      <SectionPartner />
      <SectionContact /> */}
    </>
  )
}

Home.defaultProps = {
  contents: {
    lang: 'th', 
    content: {
      contents: []
    }
  }, 
  path: '', 
  lang: 'th', 
  slides: [
    { 
      id: 1, 
      detail: <>
        <img src={Logo} alt="Logo" className="logo mb-5" />
        <div className="fs-2 text-uppercase">"Knowledge Creates Creativity"</div>
      </>, 
      row_value: Photo 
    }
  ], 
  slide_events: [
    { row_value: Photo1 }, 
    { row_value: Photo2 }, 
    { row_value: Photo3 }
  ]
}

export default Home